.info-cards{
    width: 345px;
    margin-right: 30px;
    border-bottom: 3px solid #1976d2b8;

    .info-card-action{

        padding:0px;
      

    }
    .info-card-heading{
        font-weight: bold;
        font-size: 0.998rem;
    }
    .info-cards-paragraph{
        text-align: left;
        .info-cards-subheading{
            font-weight: bold;
            color: rgba(0, 0, 0, 0.6)
        }
    }
 
}

