@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
.footer{
    font-family: "Inter", sans-serif;
    color: white;
    background: grey;
    padding:25px;
    margin-top: 20px;
    .footer-content{
        text-align: left;
        font-size: 14px;
        .footer-content-titles{
            font-weight: bold;
            margin-right:8px;
        }
    }
    .contact-us-heading {
        font-size:20px;
        font-weight: bold;
        margin-bottom: 12px;
        
    }
}