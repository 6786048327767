.services{
    margin-top: 15px;
    .service-card-background {
        background: #1976d217;
        width: 100%;
        height: 196px;
        position: absolute;
        background-image: url('../../../public/Images/app-info-image2.jpg'); 
        background-size: cover;
        /* background-position: center; */
        position: absolute;
        background-repeat: no-repeat;
        .service-card-second-background {
            background: #1976d217;
            height: 196px;
        }
    }

    .services-cards  {
       justify-content: center;
    /* background: #ffa50075; */
    // padding: 10px;
    /* height: 304px; */
    display: flex;
    /* align-items: center; */
    padding-top: 65px;
        .service-individual-cards {
            height: 214px;
            width: 274px;
            /* color: #fff; */
            box-shadow: 4px 7px 5px #2125292e;
            background: white;
            z-index: 2;
        }
        .service-card-content {
            padding:8px;
            .service-card-content-header{
                display:flex;
                align-items: center;
                padding: 10px;
                svg{
                    margin-right: 12px;
                    font-size: 38px;
                }
                p{
                    font-weight: bold;
                }
            }
            .service-card-content-body {
                height: 79px;
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    font-weight: bold;
                    font-size: 23px;
                }
            }
           
        }
        .service-card-actions{
            display: flex;
            justify-content: center;
            a{
                font-size: 0.8125rem;
                line-height: 1.75;
                letter-spacing: 0.02857em;
                text-transform: uppercase;
                min-width: 64px;
                padding: 4px 5px;
                border-radius: 4px;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                color: #1976d2;
                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            }
        }
    }
}